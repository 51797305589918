import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "mx-xl-12" }
const _hoisted_2 = { class: "create-header d-sm-flex flex-row justify-content-between" }
const _hoisted_3 = {
  key: 0,
  class: "mt-auto"
}
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = {
  key: 0,
  class: "mb-5",
  style: {"padding-left":"25px"}
}
const _hoisted_6 = { class: "d-flex justify-content-end py-6 px-9" }
const _hoisted_7 = {
  class: "row",
  style: {"display":"none"}
}
const _hoisted_8 = { class: "col-md-6" }
const _hoisted_9 = { class: "col-md-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_table_basic = _resolveComponent("table-basic")!
  const _component_UsersgroupList = _resolveComponent("UsersgroupList")!
  const _component_PermissionsList = _resolveComponent("PermissionsList")!
  const _component_UsersGroupCU = _resolveComponent("UsersGroupCU")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.canShow)
          ? (_openBlock(), _createElementBlock("h1", _hoisted_3, _toDisplayString(_ctx.$t("permissions")), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          _createVNode(_component_el_row, { justify: "end" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: _ctx.role_selected,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.role_selected) = $event)),
                name: "port",
                class: "w-sm-350px me-4",
                clearable: "",
                placeholder: `${_ctx.$t('iselect')}`,
                filterable: "",
                style: {"height":"48px"},
                remote: "",
                "reserve-keyword": "",
                onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.checkPermissions(_ctx.role_selected)))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roles, (port) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: port.id,
                      label: port.name,
                      value: port.id
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(`${port.id} ${port.name}`), 1)
                      ]),
                      _: 2
                    }, 1032, ["label", "value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue", "placeholder"]),
              (_ctx.canCreate)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "custom-btn mb-4",
                    "data-bs-toggle": "modal",
                    "data-bs-target": "#kt_modal_1",
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.createElement && _ctx.createElement(...args)))
                  }, _toDisplayString(_ctx.$t("btnNew")), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          (_ctx.canShow)
            ? (_openBlock(), _createBlock(_component_el_row, {
                key: 0,
                class: "d-flex justify-content-end"
              }, {
                default: _withCtx(() => [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.search) = $event)),
                    type: "text",
                    class: "find-btn w-sm-200px",
                    placeholder: `${_ctx.$t('isearch')}`,
                    onKeyup: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onSearch && _ctx.onSearch(...args)))
                  }, null, 40, _hoisted_4), [
                    [_vModelText, _ctx.search]
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      (_ctx.dataObject)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("button", {
              class: "custom-btn",
              onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.selectAllField && _ctx.selectAllField(...args)))
            }, _toDisplayString(_ctx.selectAllLabel), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.canShow && _ctx.dataObject)
        ? (_openBlock(), _createBlock(_component_table_basic, {
            key: 1,
            "for-permissions": true,
            class: "mt-5",
            loading: _ctx.isLoading,
            headers: _ctx.header,
            data: _ctx.dataObject,
            "full-data": _ctx.dataObject,
            buttons: _ctx.tableButtons,
            total: _ctx.dataObject.length,
            onAllFieldSelected: _ctx.allFieldSelected,
            onEventEmitPermission: _ctx.changeRole
          }, null, 8, ["loading", "headers", "data", "full-data", "buttons", "total", "onAllFieldSelected", "onEventEmitPermission"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, [
        (_ctx.canDelete && _ctx.dataObject)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: "#",
              class: "btn btn-custom btn-app-custom-delete m-1",
              onClick: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.deleteRole && _ctx.deleteRole(...args)), ["prevent"]))
            }, _toDisplayString(_ctx.$t("btnDelete")), 1))
          : _createCommentVNode("", true),
        (_ctx.canUpdate && _ctx.dataObject)
          ? (_openBlock(), _createElementBlock("a", {
              key: 1,
              href: "#",
              class: "btn btn-custom btn-app-custom m-1",
              onClick: _cache[7] || (_cache[7] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.updateRole && _ctx.updateRole(...args)), ["prevent"]))
            }, _toDisplayString(_ctx.$t("btnSave")), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_UsersgroupList, {
          items: _ctx.roles,
          onAction: _ctx.onEvent
        }, null, 8, ["items", "onAction"])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_PermissionsList, { items: _ctx.permissions }, null, 8, ["items"])
      ])
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.dialog.visible,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.dialog.visible) = $event)),
      width: "20%",
      onClose: _ctx.resetModal
    }, {
      default: _withCtx(() => [
        _createVNode(_component_UsersGroupCU, {
          "type-event": _ctx.dialog.typeEvent,
          item: _ctx.element,
          onSuccess: _ctx.successOperation
        }, null, 8, ["type-event", "item", "onSuccess"])
      ]),
      _: 1
    }, 8, ["modelValue", "onClose"])
  ], 64))
}