<template>
  <div class="card card-xxl-stretch mb-xl-3">
    <div class="card-header border-0">
      <h3 class="card-title fw-bolder text-dark">
        {{ $t("usersgroupIndex") }}
      </h3>
      <div class="card-toolbar">
        <button
          v-if="create_usersgroup"
          type="button"
          class="btn btn-primary btn-sm"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="top-end"
          @click="$emit('action', { event: 'create', ref: 'usersgroup' })"
        >
          {{ $t("btnNew") }}
        </button>
      </div>
    </div>
    <div class="card-body pt-2">
      <template v-for="rol in items" :key="rol.id">
        <div class="mb-8 d-flex align-items-center">
          <span
            :class="`bullet bullet-vertical h-40px bg-${
              colors[Math.floor(Math.random() * colors.length)]
            }`"
          ></span>
          <div
            class="flex-grow-1 mx-5"
            @click="
              $emit('action', { event: 'view', ref: 'usersgroup', data: rol })
            "
          >
            <a
              href="javascript:void(0)"
              class="text-gray-800 text-hover-primary fw-bolder fs-6"
              >{{ rol.name }}</a
            >
          </div>
          <button
            v-if="update_usersgroup"
            class="btn fs-8 fw-bolder btn-sm btn-light-success"
            @click="
              $emit('action', { event: 'update', ref: 'usersgroup', data: rol })
            "
          >
            {{ $t("btnEdit") }}
          </button>
          <button
            v-if="delete_usersgroup"
            class="btn fs-8 fw-bolder btn-sm btn-light-danger"
            @click="
              $emit('action', { event: 'delete', ref: 'usersgroup', data: rol })
            "
          >
            {{ $t("btnDelete") }}
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { hasPermission } from "@/core/helpers/functions";
import { defineComponent, ref } from "@vue/runtime-core";

export default defineComponent({
  name: "UsersgroupList",
  props: {
    items: Array,
  },
  setup() {
    return {
      colors: ref(["success", "info", "primary", "warning"]),
      delete_usersgroup: ref(hasPermission("roles: delete")),
      update_usersgroup: ref(hasPermission("roles: update")),
      create_usersgroup: ref(hasPermission("roles: create")),
    };
  },
});
</script>
