
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref, onMounted, computed } from "vue";
import UsersgroupList from "@/components/cards/UsersgroupList.vue";
import UsersGroupCU from "./UsersGroupCU.vue";
import PermissionsList from "@/components/cards/PermissionsList.vue";
import TableBasic from "@/components/tables/TableBasic.vue";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
//import Loading from '@/components/Loading.vue';
import { hasPermission } from "@/core/helpers/functions";

export default defineComponent({
  name: "ManagementUsersGroup",
  components: {
    //Loading,
    TableBasic,
    UsersgroupList,
    UsersGroupCU,
    PermissionsList,
  },
  setup() {
    const { t } = useI18n();
    const header = ref([
      { column: t("iname"), key: "name" },
      { column: t("permissions"), key: "permissions" },
    ]);
    const role_selected = ref(1);
    const isLoading = ref(true);
    const itemID = ref("");
    const modalMode = ref("add");
    const dataList = ref([]);
    const dataObject = ref({});
    const tableButtons = ref([]);
    const search = ref("");
    const roles = ref<Array<any>>([]);
    const permissions = ref<Array<any>>([]);
    const selectAllLabel = ref(t("iselectall"));
    const permissionsTemp = localStorage.getItem("permissions") as any;
    const myPermissions = {
      value: JSON.parse(permissionsTemp),
    };
    const element = ref({});
    const dialog = ref({
      ref: "",
      typeEvent: "",
      visible: false,
    });
    const canCreate = computed(() => {
      return (
        myPermissions.value.findIndex((x) => x.name === "roles: create") !== -1
      );
    });
    const canShow = computed(() => {
      return (
        myPermissions.value.findIndex((x) => x.name === "roles: show") !== -1
      );
    });
    const canUpdate = computed(() => {
      return (
        myPermissions.value.findIndex((x) => x.name === "roles: update") !== -1
      );
    });
    const canDelete = computed(() => {
      return (
        myPermissions.value.findIndex((x) => x.name === "roles: delete") !== -1
      );
    });
    const actionsButton = (param1, param2) => {
      if (param2 == "EDIT") {
        dialog.value.visible = true;
        itemID.value = param1;
        modalMode.value = "edit";
      }
    };
    const createElement = () => {
      dialog.value.visible = true;
      itemID.value = "";
      modalMode.value = "add";
    };
    const onSearch = () => {
      if (permissions.value) {
        dataObject.value = permissions.value.filter(
          (x) =>
            JSON.stringify(x)
              .toLowerCase()
              .indexOf(search.value.toLowerCase()) !== -1
        );
      }
    };
    const getRoles = () =>
      ApiService.get("/api/roles").then(({ data }) => {
        if (data.length > 0) {
          role_selected.value = data[0].id;
          checkPermissions(data[0].id);
        }
        return data;
      });

    const getPermissions = () =>
      ApiService.get("/api/permissions").then(({ data }) => {
        return data.map((p: any) => {
          p.hasPermission = false;
          return p;
        });
      });

    onMounted(async () => {
      setCurrentPageBreadcrumbs("usersgroupIndex", [
        { route: "/usersgroup", label: "permissions" },
      ]);
      roles.value = await getRoles();
      // permissions.value = await getPermissions();
    });

    // eslint-disable-next-line no-undef
    const checkPermissions = async (rol_id: BigInteger) => {
      if (canShow.value) {
        try {
          const role = (await ApiService.get(`/api/roles/${rol_id}`)).data
            .permissions_list;
          dataObject.value = role;
          permissions.value = role;
          onSearch();
          isLoading.value = false;
        } catch (error) {
          console.error(error);
        }
      }
    };

    const onEvent = (item: any = {}) => {
      if (item.event === "view") {
        checkPermissions(item.data.id);
        return;
      }
      dialog.value = {
        ref: item.ref,
        typeEvent: item.event,
        visible: true,
      };
      if (item.data) {
        element.value = item.data;
      }
    };

    const resetModal = async () => {
      dialog.value = {
        ref: "",
        typeEvent: "",
        visible: false,
      };
      element.value = {};
      roles.value = await getRoles();
      permissions.value = await getPermissions();
    };
    const successOperation = (response) => {
      if (dialog.value.ref === "usersgroup") {
        const index = roles.value.findIndex(
          (el: Record<string, any>) => el.id === response.id
        );
        if (index >= 0) {
          roles.value[index] = response;
        } else {
          roles.value.push(response);
        }
      }

      resetModal();
    };

    const changeRole = (id, value) => {
      permissions.value.forEach(function (val, keyVal) {
        val.permissions.forEach(function (perm, permVal) {
          if (perm.id === id) {
            permissions.value[keyVal].permissions[permVal].active = value;
            dataObject.value = permissions.value;
          }
        });
      });
    };

    const updateRole = () => {
      const data = {
        permissions_list: permissions.value,
      };
      ApiService.post(
        "/api/roles/" + role_selected.value + "/permissions",
        data
      ).then(function (response) {
        permissions.value = response.data.permissions_list;
      });
    };

    const deleteRole = () => {
      Swal.fire({
        text: "Esta seguro que desea eliminar a este permiso?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-primary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete("/api/roles/" + role_selected.value).then(
            async (response) => {
              roles.value = await getRoles();
              permissions.value = response.data.permissions_list;
            }
          );
        }
      });
    };

    const allFieldSelected = (key) => {
      let trueCount = 0;
      let totalCount = 0;
      permissions.value.forEach(function (val, keyVal) {
        if (key === keyVal) {
          val.permissions.forEach(function (perm, permVal) {
            if (permissions.value[keyVal].permissions[permVal].active == true) {
              trueCount += 1;
            }
            totalCount += 1;
          });
        }
      });
      let state = trueCount < totalCount ? true : false;
      permissions.value.forEach(function (val, keyVal) {
        if (key === keyVal) {
          val.permissions.forEach(function (perm, permVal) {
            permissions.value[keyVal].permissions[permVal].active = state;
            dataObject.value = permissions.value;
          });
        }
      });
    };
    const selectAllField = () => {
      let trueCount = 0;
      let totalCount = 0;
      permissions.value.forEach(function (val, keyVal) {
        val.permissions.forEach(function (perm, permVal) {
          if (permissions.value[keyVal].permissions[permVal].active == true) {
            trueCount += 1;
          }
          totalCount += 1;
        });
      });
      let state = trueCount < totalCount ? true : false;
      selectAllLabel.value = state ? t("ideselectall") : t("iselectall");
      permissions.value.forEach(function (val, keyVal) {
        val.permissions.forEach(function (perm, permVal) {
          permissions.value[keyVal].permissions[permVal].active = state;
          dataObject.value = permissions.value;
        });
      });
    };

    return {
      allFieldSelected,
      selectAllLabel,
      selectAllField,
      deleteRole,
      updateRole,
      changeRole,
      dialog,
      element,
      roles,
      permissions,
      onEvent,
      successOperation,
      resetModal,
      onSearch,
      search,
      tableButtons,
      dataObject,
      dataList,
      createElement,
      modalMode,
      isLoading,
      header,
      actionsButton,
      role_selected,
      checkPermissions,
      canShow,
      canCreate,
      canUpdate,
      canDelete,
    };
  },
});
