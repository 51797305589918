
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { translate } from "@/core/helpers/functions";
import ApiService from "@/core/services/ApiService";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "UsersgroupCU",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  props: {
    typeEvent: String,
    item: Object,
  },
  setup(props, { emit }) {
    const route = useRoute();
    const submitButton = ref<HTMLElement | null>(null);
    const title = ref("");
    const form = ref({
      id: null,
      name: "",
    });
    const inputId = ref({
      loading: false,
      options: [],
      list: [],
      value: [],
      data: [],
    });

    const getUsersGroup = () => {
      ApiService.get("/api/roles").then(({ data }) => {
        inputId.value.list = data;
      });
    };
    const selectUsersGroup = (query) => {
      if (query !== "") {
        inputId.value.loading = true;
        setTimeout(() => {
          inputId.value.loading = false;
          inputId.value.options = inputId.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        inputId.value.options = [];
      }
    };

    onMounted(() => {
      const nameRoute = route.name;
      form.value = {
        id: null,
        name: "",
      };
      if (nameRoute === "usersGroupCreate" || props.typeEvent === "create") {
        title.value = "Crear nuevo grupo de usuarios";
      } else if (
        nameRoute === "usersGroupUpdate" ||
        props.typeEvent === "update"
      ) {
        title.value = "Actualizar grupo de usuarios";
      }

      if (props.item && Object.keys(props.item).length > 0) {
        form.value.id = props.item.id;
        form.value.name = props.item.name;
      }
      if (nameRoute === "usersGroupUpdate") {
        getUsersGroup();
      }

      if (!props.typeEvent) {
        const nav =
          nameRoute == "usersGroupCreate"
            ? "usersGroupCreate"
            : "usersGroupUpdate";
        setCurrentPageBreadcrumbs(nav, ["user", "usersGroup"], false);
      }
    });
    const createUsersGroup = (params) => {
      ApiService.post("/api/roles", params)
        .then(({ data }) => {
          emit("success", data);
          submitButton.value?.removeAttribute("data-kt-indicator");
          form.value = {
            id: null,
            name: "",
          };
        })
        .catch(() => {
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const updateUsersGroup = (params) => {
      ApiService.put("/api/roles/" + form.value.id, params)
        .then(({ data }) => {
          emit("success", data);
          submitButton.value?.removeAttribute("data-kt-indicator");
        })
        .catch(() => {
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const vusersGroup = Yup.object().shape({
      name: Yup.string().required(translate("rname")).label("Name"),
    });

    const onSubmitCG = (values) => {
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
        if (form.value.id) {
          updateUsersGroup(form.value);
        } else {
          createUsersGroup(values);
        }
      }
    };

    return {
      route,
      title,
      submitButton,
      vusersGroup,
      form,
      inputId,
      onSubmitCG,
      createUsersGroup,
      updateUsersGroup,
      getUsersGroup,
      selectUsersGroup,
    };
  },
});
